.signatureCanvas {
  border: 1px solid black;
  width: 350px;
  height: 330px; /* Valor predeterminado */
}

@media (max-width: 700px) {
.signatureCanvas {
  height: 430px; /* Altura para pantallas menores a 700px */
  width: 320px;

}
}